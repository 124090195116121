import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SoBanner from 'components/SoBanner';

import { ProductListProps } from './models';

const ProductList: FC<{ data: ProductListProps }> = ({
  data: {
    productList: { urls, seo, content },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const [products, setProducts] = useState<{ itemName: string }[]>([]);

  const cleanHTML = (htmlString: string): string =>
    new DOMParser().parseFromString(htmlString, 'text/html').body.textContent || '';

  useEffect(() => {
    content.forEach((container) => {
      if (container.extraClass.includes('section__produt-list')) {
        container.items.forEach((data) => {
          setProducts((prevProducts) => [
            ...prevProducts,
            { itemName: cleanHTML(data.bannerText) },
          ]);
        });
      }
    });
  }, [content]);

  return (
    <Layout headerTransparent>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />

      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        tagEventData={{
          eventType: 'view_item_list',
          tagData: {
            value: seoTitle,
            ecommerce: {
              items: products,
            },
          },
        }}
      />
      <div id="home" className="mx-auto section-container">
        {content?.map((data, index) => {
          const indexKey = index + Date.now();

          return <SoBanner data={data} index={index} key={indexKey} />;
        })}
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    productList {
      content {
        title
        subtitle
        variant
        containerText
        pageSize
        containerImage {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
        }
        extraClass
        showPager
        showThumbs
        showArrows
        showStatus
        infiniteLoop
        items {
          variant
          extraClass
          legalText
          bannerText
          buttonType
          button {
            url
            icon
            name
            published
            udi
            queryString
          }
          imageDesktop {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 1920)
              }
            }
          }
          imageMobile {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageProduct {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageRecipe {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
        }
      }
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        label
        url {
          url
        }
        navigationWithSubMenu {
          label
          url {
            name
            url
          }
        }
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default ProductList;
